<template>
  <div class="container">
    <v-card :loading="loading" class="my-2">
      <v-card-title class="d-flex justify-space-between">
        <span class="text-subtitle-1">{{ order.customerName }}</span>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              class="ml-0 ml-md-2"
              color="primary"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-if="order.status == 'Completed'"
              @click="markOrderAsIncomplete"
              ripple
            >
              <v-list-item-icon
                ><v-icon>mdi-check-circle-outline</v-icon></v-list-item-icon
              >
              <v-list-item-title>Mark as Incomplete</v-list-item-title>
            </v-list-item>
            <v-list-item v-else @click="markOrderAsCompleted" ripple>
              <v-list-item-icon
                ><v-icon>mdi-check-circle-outline</v-icon></v-list-item-icon
              >
              <v-list-item-title>Mark as Completed</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-title>
      <v-card-subtitle class="d-flex flex-row justify-space-around">
        <span>ID: {{ order.id }}</span> <v-divider vertical class="mx-2" />
        <span>Code: {{ order.salesOrderCode }}</span>
        <v-divider vertical class="mx-2" />
        <span>Status: {{ order.status }}</span>
        <v-divider vertical class="mx-2" />
        <span>Date: {{ order.salesDate | formatDateYear }}</span>
      </v-card-subtitle>
      <v-card-text>
        <div v-if="order.summaries">
          <div
            v-for="summary in order.summaries"
            :key="summary.productId"
            class="d-flex justify-space-between flex-column flex-md-row my-4"
            :set="
              (measureUnit =
                summary.product.measureType == 'unit'
                  ? summary.product.measureUnit
                  : summary.product.measureWeight)
            "
          >
            <div
              class="flex-grow-1 text-center mx-0 mx-md-8"
              :set="(percent = percentFilled(summary))"
            >
              <div>{{ summary.product.name }}</div>
              <v-progress-linear
                class="pointer"
                v-ripple
                @click="showPackingList"
                :value="percent"
                color="primary"
                height="25"
              >
                <template v-slot:default="{ value }">
                  <strong>{{ Math.ceil(value) }}%</strong>
                </template>
              </v-progress-linear>
              <div class="text-caption">
                {{ summary.filledWeight | formatNumber }} of
                {{ summary.weight | formatNumber }}
                {{ measureUnit }}
              </div>
            </div>
            <div
              :key="statusUpdateTs"
              class="my-4 my-md-auto"
              :set="
                (pendings = getPendingPackages(
                  order.customerId,
                  summary.productId
                ))
              "
            >
              <v-btn
                v-if="order.status != 'Completed'"
                :disabled="!pendings || pendings.length == 0"
                @click="viewWorksheets(order, summary.productId)"
                outlined
                small
              >
                {{ pendings ? pendings.length : 0 }} Pending Worksheets
              </v-btn>
            </div>
          </div>
        </div>
        <div v-else class="text-center text-h6 ma-4">No Product</div>
        <div class="text-caption text-right">
          {{ order.salesDate | formatDateYear }} by
          {{ order.salesPersonName }}
        </div>
      </v-card-text>
    </v-card>

    <v-dialog v-model="worksheetDialog" style="z-index: 1000">
      <v-card>
        <v-card-title class="justify-end">
          <div>
            <v-btn @click="worksheetDialog = null" icon
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </div>
        </v-card-title>
        <v-card-text>
          <worksheet-card
            v-for="(worksheet, index) in selectedWorksheets"
            :worksheet="worksheet"
            :key="worksheet ? worksheet.id : 'null'"
            :showEditButtons="true"
            :editButtons="['print', 'save-packing-list']"
            :index="index + 1"
            :salesOrderId="order.id"
            @print="print"
            @used="usedWorksheet"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="packingListDialog" style="z-index: 1000">
      <v-card>
        <v-card-title class="justify-end">
          <div>
            <v-btn @click="packingListDialog = null" icon
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </div>
        </v-card-title>
        <v-card-subtitle class="text-center font-weight-light">
          {{ $vuetify.lang.t("$vuetify.label.foundX", packingLists ? packingLists.length : 0 ) }}
        </v-card-subtitle>
        <v-card-text>
          <packing-list
            class="ma-4"
            :items="packingLists"
            @updated="packingListChanged"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
const WorksheetCard = () =>
  import("@/components/processWorksheet/WorksheetCard.vue");
const PackingList = () => import("@/components/packaging/PackingList.vue");
import { mapActions } from "vuex";
import {
  fetchOrdersSummary,
  fetchOrderPackingList,
  markSalesOrderAsCompleted,
  markSalesOrderAsIncomplete,
} from "@/services/orderServices";

export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    selectedWorksheets: [],
    worksheetDialog: false,
    packingListDialog: false,
    packingLists: [],
    pendingPackings: [],
    statusUpdateTs: Date.now(),
  }),
  components: { WorksheetCard, PackingList },
  created() {
    this.fetchPendingPackingWorksheets();
  },
  methods: {
    ...mapActions("processWorksheet", ["fetchProcessWorksheets"]),
    fetchPendingPackingWorksheets() {
      this.loading = true;
      const customerId = this.order.customerId;
      const orderId = this.order.id;

      console.log("fetching packing list for order id: " + orderId);
      this.$axios
        .get("/processWorksheet/pending-packing", {
          params: { customerId, orderId },
        })
        .then((resp) => {
          const pendings = resp.data;
          if (pendings && pendings.length > 0) {
            pendings.forEach((p) => {
              const existIndex = this.pendingPackings.findIndex(
                (pp) => pp.id == p.id
              );
              if (existIndex < 0) {
                this.pendingPackings.push(p);
              }
            });
          }
          this.loading = false;
        })
        .catch((error) => {
          console.error(error);
          this.loading = false;
        });
    },
    packingListChanged(packingList) {
      this.packingListDialog = false;
      //removed used worksheet
      const plIndex = this.packingLists.findIndex(
        (pl) => pl.id == packingList.id
      );
      this.packingLists.splice(plIndex, 1);
      fetchOrdersSummary([this.order]).then((ordersSummary) => {
        ordersSummary.forEach((s) => {
          const list = [s];
          this.$set(this.order, "summaries", list);
        });
      });

      this.pendingPackings = [];
      this.fetchPendingPackingWorksheets();
    },
    usedWorksheet(worksheet) {
      this.worksheetDialog = false;
      //removed used worksheet
      const usedWsIndex = this.pendingPackings.findIndex(
        (ws) => ws.id == worksheet.id
      );
      this.pendingPackings.splice(usedWsIndex, 1);
      this.statusUpdateTs = Date.now();
      fetchOrdersSummary([this.order]).then((ordersSummary) => {
        ordersSummary.forEach((s) => {
          const list = [s];
          this.$set(this.order, "summaries", list);
        });
      });
    },
    getPendingPackages(customerId, productId) {
      // console.log(`find pending for ${customerId} and ${productId}`);
      return this.pendingPackings.filter(
        (p) => p.customerId == customerId && p.productId == productId
      );
      // return this.pendingPackings.filter((p) => p.customerId == customerId);
    },
    percentFilled(summary) {
      const measureByUnit = summary.product.measureType == "unit";
      const numerator = measureByUnit
        ? summary.filledUnit
        : summary.filledWeight;
      const denominator = measureByUnit ? summary.unit : summary.weight;
      if (denominator) {
        return ((numerator / denominator) * 100).toFixed(0);
      } else {
        return 0;
      }
    },
    print(item) {
      this.$router.push({
        name: "worksheetPackingSlip",
        params: { id: item.id },
      });
    },
    viewWorksheets(order, productId) {
      const customerId = order.customerId;
      this.selectedWorksheets = this.getPendingPackages(customerId, productId);
      this.worksheetDialog = true;
    },
    markOrderAsCompleted() {
      markSalesOrderAsCompleted(this.order.id).then(() => {
        this.order.status = "Completed";
      });
    },
    markOrderAsIncomplete() {
      markSalesOrderAsIncomplete(this.order.id).then(() => {
        this.order.status = "Partially Filled";
      });
    },
    showPackingList() {
      console.log("display packing list for order");
      fetchOrderPackingList(this.order.id).then((resp) => {
        this.packingLists = resp.data;
        this.packingListDialog = true;
      });
    },
  },
};
</script>

<style>
.pointer {
  cursor: pointer;
}
</style>
